var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: "RESOURCE_BADGE_GROUP_MODAL" },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "footer-class": "footerClass",
            title: "Edit Assigned Resource",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            scrollable: "",
          },
          on: { hide: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.ok },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-form-group",
            [
              _c("label", { staticClass: "mr-1" }, [
                _vm._v(_vm._s(_vm.$t("staff.field.resources"))),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn-action",
                  on: { click: _vm.resourceSelectorToggle },
                },
                [_c("font-awesome-icon", { attrs: { icon: ["far", "plus"] } })],
                1
              ),
              _c("BadgeGroup", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var item = ref.item
                      var index = ref.index
                      return [
                        _c("Badge", {
                          key: index,
                          attrs: {
                            text:
                              item.name +
                              " " +
                              _vm.formatResourceUnit(item.unit),
                            variant: "primary",
                            pillable: !!item.pillable,
                          },
                          on: {
                            badgeRemove: function ($event) {
                              return _vm.resourceBadgeRemove(index)
                            },
                            badgeClick: function ($event) {
                              return _vm.resourceBadgeClick(item.uuId)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.resources,
                  callback: function ($$v) {
                    _vm.resources = $$v
                  },
                  expression: "resources",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.resourceSelectorShow
        ? _c("ResourceSelectorModal", {
            attrs: { show: _vm.resourceSelectorShow },
            on: {
              "update:show": function ($event) {
                _vm.resourceSelectorShow = $event
              },
              cancel: _vm.resourceSelectorCancel,
              ok: _vm.resourceSelectorOk,
            },
          })
        : _vm._e(),
      _c("ResourceUnitModal", {
        attrs: {
          show: _vm.resourceUnitEditShow,
          uuId: _vm.resourceUnitEdit.uuId,
          name: _vm.resourceUnitEdit.name,
          unit: _vm.resourceUnitEdit.unit,
          utilization: parseFloat(_vm.resourceUnitEdit.utilization),
          cData: _vm.resourceUnitEdit,
          edgeName: _vm.edgeName,
        },
        on: {
          "update:show": function ($event) {
            _vm.resourceUnitEditShow = $event
          },
          ok: _vm.resourceUnitOk,
        },
      }),
      _vm._l(_vm.toConfirmResources, function (item, index) {
        return [
          _c("ResourceUnitModal", {
            key: "resource-" + index,
            attrs: {
              show: item.show,
              uuId: item.uuId,
              name: item.name,
              unit: parseInt(item.unit),
              utilization: parseFloat(item.utilization),
              cData: item,
              edgeName: _vm.edgeName,
              canApplyAll: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(item, "show", $event)
              },
              ok: _vm.toConfirmResourceOk,
              cancel: _vm.toConfirmResourceCancel,
            },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }